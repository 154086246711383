






import Vue from 'vue'
import { enhancerMixin } from '../mixins'

export default Vue.extend({
  name: 'TitleSmall',
  mixins: [enhancerMixin({ as: 'h3' })]
})
