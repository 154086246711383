






import Vue from 'vue'
import { enhancerMixin } from '../mixins'

export default Vue.extend({
  name: 'CaptionSmall',
  mixins: [
    enhancerMixin({
      as: 'p'
    })
  ]
})
