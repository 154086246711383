






import Vue from 'vue'
import { enhancerMixin } from '../mixins'

export default Vue.extend({
  name: 'SubHeading',
  mixins: [
    enhancerMixin({
      as: 'h5'
    })
  ]
})
