











import Vue from 'vue'
import { enhancerMixin } from '../mixins'

export default Vue.extend({
  name: 'Heading',
  mixins: [
    enhancerMixin({
      as: 'h4'
    })
  ],
  props: {
    headerLayout: {
      type: String,
      required: false,
      default: 'main-layout'
    }
  }
})
