





























import Vue from 'vue'

export default Vue.extend({
  name: 'Button',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    colorSize: {
      type: String,
      required: false,
      default: 'yellow_normal'
    },
    kind: {
      type: String,
      required: false,
      default: null,
      validator: kind => ['ghost'].includes(kind)
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: size => ['medium', 'small'].includes(size)
    },
    center: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: Object,
      required: false,
      default: null
    }
  }
})
