export const enhancerMixin = ({
  weight = null,
  align = null,
  color = null,
  as,
  to = null
} = {}) => ({
  props: {
    /**
     * Any html tag default is p
     */
    as: {
      type: [String, Function],
      required: false,
      default: as
    },
    /**
     * enum: ['400', '500', '700']
     */
    weight: {
      type: String,
      required: false,
      default: weight,
      validator: weight => ['400', '500', '700'].includes(weight)
    },
    /**
     * enum: ['left', 'center', 'right']
     */
    align: {
      type: String,
      required: false,
      default: align
    },
    /**
     * enum: ['outer-space', 'black', 'dark-gray', 'caption-gray', 'white', 'crimson']
     */
    color: {
      type: String,
      required: false,
      default: color,
      validator: color =>
        [
          'outer-space',
          'black',
          'dark-gray',
          'caption-gray',
          'white',
          'crimson'
        ].includes(color)
    },
    /**
     * In case typography become anchor (nuxt-link)
     */
    to: {
      type: [String, Object],
      required: false,
      default: to
    }
  },
  computed: {
    enhancerClassName() {
      return [
        this.weight ? `weight-${this.weight}` : '',
        this.align ? `align-${this.align}` : '',
        this.color ? `color-${this.color}` : ''
      ]
    }
  }
})
