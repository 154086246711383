<template>
  <div class="{loadingPage}"></div>
</template>

<script>
export default {
  name: 'LoadingDonut',
  props: {
    loadingPage: {
      type: String,
      required: false,
      default: 'donut'
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.donut_loading {
  display: inline-block;
  border: 0.25rem solid $yellow;
  border-left-color: #fff;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  animation: donut-spin 1.2s linear infinite;
}
</style>
