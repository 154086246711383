




































































































import Vue from 'vue'
import { convertExtensionToWebp } from '~/services/utils'
export default Vue.extend({
  name: 'Picture',
  props: {
    externalImageSource: {
      type: Boolean,
      required: false,
      default: false
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false
    },
    desktopImageFileName: {
      type: String,
      required: false,
      default: null
    },
    tabletImageFileName: {
      type: String,
      required: false,
      default: null
    },
    imageFileName: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: false,
      default: null
    },
    height: {
      type: String,
      required: false,
      default: null
    },
    width: {
      type: String,
      required: false,
      default: null
    },
    alt: {
      type: String,
      default: null,
      required: false
    },
    dataTest: {
      type: String,
      default: null,
      required: false
    }
  },
  computed: {
    imageFileNameWebpExt(): string {
      return convertExtensionToWebp(this.imageFileName)
    },
    tabletImageFileNameWebpExt(): string {
      return convertExtensionToWebp(this.tabletImageFileName)
    },
    desktopImageFileNameWebpExt(): string {
      return convertExtensionToWebp(this.desktopImageFileName)
    }
  }
})
