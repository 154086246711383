

















import Vue from 'vue'
import {
  Heading,
  TitleMedium,
  TextBody
} from '~/components/commons/bases/typography'

export default Vue.extend({
  name: 'ContentContainer',
  components: {
    Heading,
    TitleMedium,
    TextBody
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    titleTag: {
      type: String,
      required: false,
      default: 'Medium',
      validator: color => ['Large', 'Medium', 'Small'].includes(color)
    }
  },
  computed: {
    showTitle() {
      if (this.title.length > 1) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    isMedium(type, title): boolean {
      let result = false
      if (title === '') {
        result = false
      } else {
        result = true
        if (type !== 'Medium') {
          result = false
        }
      }
      return result
    },
    isSmall(type, title): boolean {
      let result = false
      if (title === '') {
        result = false
      } else {
        result = true
        if (type !== 'Small') {
          result = false
        }
      }
      return result
    }
  }
})
